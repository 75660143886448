<template>
  <div class="infomation main_top">
    <div class="agree_title">{{ $t("key644") }}</div>
    <div class="agree_conatiner">
      <div class="container_top">{{ $t("key645") }}</div>
      <div
        class="container_info"
        infinite-scroll-distance="1"
        :infinite-scroll-immediate="false"
      >
        <div class="agreement__protocol" data-v-36e0b530="">
          <h2>1.{{ $t("key646") }}</h2>
          <p>
            {{ $t("key647") }}
          </p>
          <h2 data-v-2eb17a49="">2.{{ $t("key648") }}</h2>
          <p>
            {{ $t("key649") }}
          </p>
          <h2 data-v-2eb17a49="">3.{{ $t("key650") }}</h2>
          <p data-v-2eb17a49="">
            {{ $t("key651") }}
          </p>
          <h2 data-v-2eb17a49="">4.{{ $t("key652") }}</h2>
          <p data-v-2eb17a49="">
            {{ $t("key653") }}
          </p>
          <p data-v-2eb17a49="">
            {{ $t("key654") }}
          </p>
          <p data-v-2eb17a49="">
            <b data-v-2eb17a49="">4.3 {{ $t("key655_1") }}</b
            >{{ $t("key655") }}
          </p>
          <h2 data-v-2eb17a49="">5.{{ $t("key656") }}</h2>
          <p data-v-2eb17a49="">
            5.1 {{ $t('key657') }}
          </p>
          <p data-v-2eb17a49="">
            5.2 {{ $t('key658') }}
          </p>
          <p data-v-2eb17a49="">
            5.3 {{ $t('key659') }}
          </p>
          <h2 data-v-2eb17a49="">6.{{ $t('key660') }}</h2>
          <p data-v-2eb17a49="">
            6.1 {{ $t('key661') }}
          </p>
          <p data-v-2eb17a49="">
            6.2 {{ $t('key663') }}
          </p>
          <p data-v-2eb17a49="">
            6.4 {{ $t('key663') }}
          </p>
          <p data-v-2eb17a49="">
            6.5 {{ $t('key664') }}
          </p>
          <p data-v-2eb17a49="">
            6.6 {{ $t('key665') }}
          </p>
          <p data-v-2eb17a49="">
            6.7 {{ $t('key666') }}
          </p>
          <p data-v-2eb17a49="">
            6.8 {{ $t('key667') }}
          </p>
          <p data-v-2eb17a49="">
            6.9 {{ $t('key668') }}
          </p>
        </div>
      </div>
      <div class="agree_handler">
        <div class="agree_btn flex_center" @click="toPage('/affiliate')">
          {{ $t('key669') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toPage(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.agree_title {
  margin-top: 20px;
  padding: 12px 16px;
  color: #0f0f0f;
  font-size: 16px;
  text-align: left;
  background: #fff;
  border-radius: 6px;
  font-weight: 600;
}
.agree_conatiner {
  margin-top: 8px;
  background: #fff;
  border-radius: 6px;
  position: relative;
  padding-bottom: 100px;
  .container_top {
    margin: 0 32px;
    padding: 32px 0;
    color: #0f0f0f;
    border-bottom: 1px solid #e8e9ed;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }
  .container_info {
    overflow: scroll;
    margin: 0 32px;
    height: calc(100vh - 424px);
    .agreement__protocol {
      padding: 32px 0;
    }
  }
  .agree_handler {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 93px;
    background: #fff;
    box-shadow: 0 -4px 4px -1px rgba(0, 0, 0, 0.1),
      0 -5px 5px 0 rgba(0, 0, 0, 0.08), 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
    .agree_btn {
      width: 343px;
      height: 48px;
      background: #ffd633;
      border-radius: 4px;
      box-shadow: 0px 0px 4px -1px rgba(0, 0, 0, 0.1),
        0px 0px 5px 0px rgba(0, 0, 0, 0.08),
        0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      cursor: pointer;
    }
  }
}
</style>